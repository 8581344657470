var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-card",
        { attrs: { title: "교육교재", height: "600px" } },
        [
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("c-upload", {
                  attrs: {
                    attachInfo: _vm.attachEduPlanInfo,
                    label: "교육계획 관련 파일",
                    editable: _vm.editable && !_vm.disabled,
                  },
                }),
              ],
              1
            ),
          ]),
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("c-upload", {
                  attrs: {
                    attachInfo: _vm.attachEduCourseInfo,
                    label: "교육과정 관련 파일",
                    editable: false,
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }